import { Injectable } from '@angular/core';
import { KeycloakService, KeycloakEvent, KeycloakEventType } from 'keycloak-angular';
import { KeycloakProfile } from 'keycloak-js';

@Injectable()
export class AuthService {
  constructor(private keycloak: KeycloakService) {
    keycloak.keycloakEvents$.subscribe({
      next: (e: KeycloakEvent) => {
        console.log('KeycloakEvent', e.type == KeycloakEventType.OnTokenExpired, e);
        if (e.type == KeycloakEventType.OnTokenExpired) {
          keycloak.updateToken();
        }
      },
    });
  }

  public login(): Promise<void> {
    return this.keycloak.login({ redirectUri: window.location.origin });
  }

  public updatePassword(): Promise<void> {
    return this.keycloak.login({ redirectUri: window.location.origin, action: 'UPDATE_PASSWORD', prompt: 'login' });
  }

  public logout(): Promise<void> {
    return this.keycloak.logout(window.location.origin + '/login');
  }

  public getUser(): Promise<KeycloakProfile> {
    return this.keycloak.loadUserProfile();
  }

  public isLoggedIn(): Promise<boolean> {
    return this.keycloak.isLoggedIn();
  }
}
