import { importProvidersFrom, isDevMode } from '@angular/core';
import { ApplicationConfig, BrowserModule } from '@angular/platform-browser';
import {
  HTTP_INTERCEPTORS,
  HttpClientJsonpModule,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { AuthModule } from 'src/app/auth/auth.module';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { HeaderInterceptor } from 'src/app/shared/interceptors/headers.interceptor';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideTransloco } from '@ngneat/transloco';
import { TranslocoHttpLoader } from 'src/transloco-loader';
import { provideTranslocoMessageformat } from '@ngneat/transloco-messageformat';
import { AuthGuard } from 'src/app/auth/auth.guard';
import { provideRouter } from '@angular/router';

export const appConfig: ApplicationConfig = {
  providers: [
    provideHttpClient(withInterceptorsFromDi()),
    provideTransloco({
      config: {
        availableLangs: ['fr', 'en', 'de'],
        defaultLang: 'fr',
        fallbackLang: 'fr',
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        prodMode: !isDevMode(),
      },
      loader: TranslocoHttpLoader,
    }),
    provideTranslocoMessageformat(), // for pluralization https://jsverse.github.io/transloco/docs/plugins/message-format
    importProvidersFrom(BrowserModule, HttpClientJsonpModule, AuthModule, NgxSkeletonLoaderModule),
    { provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true },
    provideAnimations(),
    provideRouter([
      {
        path: '',
        canActivate: [AuthGuard],
        loadChildren: () => import('./app/home/home.routes').then(m => m.HomeRoutes),
      },
    ]),
  ],
};
