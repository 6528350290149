import { APP_INITIALIZER, NgModule } from '@angular/core';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { environment } from 'src/environments/environment';
import { AuthGuard } from './auth.guard';
import { AuthService } from './auth.service';

function initializeKeycloak(keycloak: KeycloakService) {
  return () =>
    keycloak.init({
      config: environment.oauth,
      initOptions: {
        pkceMethod: 'S256',
        silentCheckSsoFallback: false,
        checkLoginIframe: false,
        onLoad: 'check-sso',
        scope: 'm3-owners-portal-api',
        // redirectUri: 'http://localhost:4200/login'
      },
      bearerExcludedUrls: ['maps.googleapis.com'],
    });
}

@NgModule({
  imports: [KeycloakAngularModule],
  providers: [
    AuthGuard,
    AuthService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService],
    },
  ],
})
export class AuthModule {}
