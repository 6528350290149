import { secret } from './environment.secret';

export const environment = {
  production: false,
  // api: 'http://localhost:5001/api/m3/owner',
  api: 'https://api.dev.digital.m-3.com/owner',
  oauth: {
    url: 'https://login.dev.digital.m-3.com/',
    realm: 'm3',
    clientId: 'm3-owners-portal-client',
  },
  ...secret,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
