import { secret } from './environment.secret';

export const environment = {
  production: true,
  api: 'https://api.digital.m-3.com/owner',
  oauth: {
    url: 'https://login.digital.m-3.com/',
    realm: 'm3',
    clientId: 'm3-owners-portal-client',
  },
  ...secret,
};
