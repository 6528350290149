import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.method === 'JSONP') return next.handle(req);
    const authReq: HttpRequest<any> = req.clone({
      headers: req.headers.set('Accept-Language', localStorage.getItem('lang')!),
    });
    return next.handle(authReq);
  }
}
