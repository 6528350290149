import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { getBrowserLang } from '@ngneat/transloco';

@Component({
  selector: 'app-root',
  template: `<router-outlet></router-outlet>`,
  standalone: true,
  imports: [RouterOutlet],
})
export class AppComponent {
  constructor(translocoService: TranslocoService) {
    if (!localStorage.getItem('lang')) localStorage.setItem('lang', getBrowserLang()?.substring(0, 2) || 'fr');
    translocoService.setActiveLang(localStorage.getItem('lang')!);
  }
}
